<template>
  <div class="container">
    <div class="form flex">
      <div class="login">
        <div class="form-content flex-dc lg">
          <div class="login_main" v-if="login == 'login'">
            <div class="login_title ft1">登录账号</div>
            <div class="ft2">
              <span>还没有账户？</span>
              <el-button
                type="text"
                class="ft2"
                @click="loginChange('register')"
                >创建账户</el-button
              >
            </div>
            <div class="blue_line"></div>
            <!-- 登录表单 -->
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item prop="username">
                <el-input v-model="form.username" placeholder="请输入用户名">
                  <i
                    class="iconfont icon-yonghu el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="form.password"
                  placeholder="请输入密码"
                  show-password
                  maxlength="16"
                >
                  <i
                    class="iconfont icon-mima el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="verCode">
                <div class="flex">
                  <el-input v-model="form.verCode" placeholder="验证码">
                    <i
                      class="iconfont icon-mima el-input__icon icon_style"
                      slot="prefix"
                    >
                    </i>
                  </el-input>
                  <div
                    class="verCode"
                    @click="getVerCode"
                    :style="{
                      backgroundImage: `url(${codeURL})`,
                      backgroundSize: '100% 100%',
                    }"
                  ></div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="form-btn"
                  block
                  type="primary"
                  :loading="loading"
                  @click="doLogin"
                  style="width: 100%"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
            <div class="check_pass">
              <el-checkbox v-model="rememberPwd" style="color: #999999"
                >记住密码</el-checkbox
              >
              <el-button
                type="text"
                style="color: #999999"
                @click="loginChange('forget')"
                >忘记密码？</el-button
              >
            </div>
          </div>
          <div class="login_main" v-else-if="login == 'register'">
            <div class="login_title ft1">注册账号</div>
            <div class="ft2">
              <span>已有账户？</span>
              <!-- <span>创建账户</span> -->
              <el-button type="text" class="ft2" @click="loginChange('login')"
                >去登录</el-button
              >
            </div>
            <div class="blue_line"></div>
            <!-- 创建账户-注册表单 -->
            <el-form ref="regForm" :model="regForm" :rules="rules">
              <el-form-item prop="phone">
                <el-input v-model="regForm.phone" placeholder="请输入手机号">
                  <i
                    class="iconfont icon-bianzu4 el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="regVerCode">
                <el-input
                  v-model="regForm.regVerCode"
                  placeholder="请输入短信验证码"
                  autocomplete="off"
                  readonly
	                onfocus="this.removeAttribute('readonly');" 
                >
                  <i
                    class="iconfont icon-verify el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                  <el-button
                    v-if="!getMsgCode"
                    slot="suffix"
                    type="text"
                    size="mini"
                    style="margin: 6px 6px 0 0"
                    @click="regGetCode('reg')"
                    >获取验证码</el-button
                  >
                  <div
                    v-else
                    slot="suffix"
                    style="margin-right: 6px; color: #3477f6"
                  >
                    {{ second + "S" }}
                  </div>
                </el-input>
              </el-form-item>
              <el-form-item prop="project">
                <el-input
                  v-model="regForm.project"
                  placeholder="请输入项目名称"
                  maxlength="50"
                >
                  <i
                    class="iconfont icon-xiangmumingcheng el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  v-model="regForm.pwd"
                  placeholder="请输入密码"
                  show-password
                  maxlength="16"
                  autocomplete="new-password"
                >
                  <i
                    class="iconfont icon-mima el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="rePwd">
                <el-input
                  v-model="regForm.rePwd"
                  placeholder="请再次输入密码"
                  show-password
                  maxlength="16"
                  autocomplete="new-password"
                >
                  <i
                    class="iconfont icon-mima el-input__icon icon_style"
                    slot="prefix"
                  >
                  </i>
                </el-input>
              </el-form-item>
            </el-form>
            <el-button
              class="form-btn"
              block
              type="primary"
              :loading="loading"
              @click="register"
              style="width: 100%"
              >注册</el-button
            >
          </div>
          <!-- 忘记密码-账户验证 -->
          <div class="login_main" v-else>
            <div class="login_title ft1">账户验证</div>
            <el-button type="text" class="ft2" @click="forgetBack"
                >去登录</el-button
              >
            <div class="blue_line"></div>
            <!--  -->
            <el-form ref="forgetForm" :model="forgetForm" :rules="rules">
              <div v-if="checkPhone">
                <el-form-item prop="phone">
                  <el-input
                    v-model="forgetForm.phone"
                    placeholder="请输入手机号"
                  >
                    <i
                      class="iconfont icon-bianzu4 el-input__icon icon_style"
                      slot="prefix"
                    >
                    </i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="regVerCode">
                  <el-input
                    v-model="forgetForm.regVerCode"
                    placeholder="请输入短信验证码"
                    autocomplete="off"
                  >
                    <i
                      class="iconfont icon-verify el-input__icon icon_style"
                      slot="prefix"
                    >
                    </i>
                    <el-button
                      v-if="!getMsgCode"
                      slot="suffix"
                      type="text"
                      size="mini"
                      style="margin: 6px 6px 0 0"
                      @click="forgetGetCode('for')"
                      >获取验证码</el-button
                    >
                    <div
                      v-else
                      slot="suffix"
                      style="margin-right: 6px; color: #3477f6"
                    >
                      {{ second + "S" }}
                    </div>
                  </el-input>
                </el-form-item>
              </div>
              <template v-else>
                <el-form-item prop="pwd">
                  <el-input
                    v-model="forgetForm.pwd"
                    placeholder="请输入密码"
                    show-password
                    maxlength="16"
                    autocomplete="new-password"
                  >
                    <i
                      class="iconfont icon-mima el-input__icon icon_style"
                      slot="prefix"
                    >
                    </i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="rePwd2">
                  <el-input
                    v-model="forgetForm.rePwd2"
                    placeholder="请再次输入密码"
                    show-password
                    maxlength="16"
                    autocomplete="new-password"
                  >
                    <i
                      class="iconfont icon-mima el-input__icon icon_style"
                      slot="prefix"
                    >
                    </i>
                  </el-input>
                </el-form-item>
              </template>
              <el-form-item>
                <el-button
                  class="form-btn"
                  block
                  type="primary"
                  :loading="loading"
                  v-if="checkPhone"
                  @click="verPhone"
                  style="width: 100%"
                  >确定</el-button
                >
                <el-button
                  class="form-btn"
                  block
                  type="primary"
                  v-else
                  :loading="loading"
                  @click="toSetPwd"
                  style="width: 100%"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核弹窗 -->
    <div>
      <custom-dialog
        :show="show"
        title="提示"
        width="500"
        @toggleShow="show = false"
        :notPaddingTop="false"
      >
        <div slot="main" class="main">
          <div></div>
          <div class="content">账号审核中，请稍等...</div>
          <div>一般会在1-5个工作日内审核通过，如有疑问请联系管理员</div>
        </div>
        <div class="flex btns" slot="footer">
          <el-button
            type="primary"
            class="btn save"
            size="small"
            @click="show = false"
          >
            确定
          </el-button>
        </div>
      </custom-dialog>
    </div>
    <!-- 短信图形验证码 -->
    <div>
      <custom-dialog
        :show="codeShow"
        title="验证码"
        width="400"
        @toggleShow="codeShow = false"
      >
        <div slot="main" style="display: flex; justify-content: space-around">
          <div
            class="verCode"
            :style="{
              backgroundImage: `url(${codeURL})`,
              backgroundSize: '100% 100%',
            }"
            @click="getVerCode"
          ></div>
          <div>
            <el-form
              size="small"
              :model="codeForm"
              :rules="rules"
              ref="codeForm"
              style="display: flex; align-items: center"
            >
              <el-form-item label="" prop="code">
                <el-input
                  v-model="codeForm.code"
                  placeholder="请输入验证码"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex btns" slot="footer">
          <el-button
            type="primary"
            class="btn save"
            size="small"
            @click="setTime"
          >
            确定
          </el-button>
        </div>
      </custom-dialog>
      <!-- @click="submit" -->
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import CustomDialog from "../../components/public/customDialog";
import { accountRegex, pwdRegex } from "../../utils/regExp";
import { getCookie, setCookie, delCookie } from "@/utils/cookie";
import { login, getCode, getMsgCode, register, resetPwd } from "@/api/index";
import { Message } from "element-ui";
export default {
  components: {
    CustomDialog,
  },
  data() {
    let validValueNumAndLetter = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入密码`));
      } else if (!this.$tool.regExp.validPwd(value)) {
        callback(
          new Error(
            `密码由8-16位数字、大小写字母或符号组成。至少含2种以上字符类型`
          )
        );
      } else {
        callback();
      }
    };
    // 验证码
    let validVerCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入验证码`));
      } else {
        callback();
      }
      // else if (!this.$tool.regExp.validValueNumAndLetter(value)) {
      //   callback(new Error(`验证码格式有误`));
      // }
    };
    let validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入手机号`));
      } else if (!this.$tool.regExp.validPhone(value)) {
        callback(new Error(`请输入正确的手机号`));
      } else {
        callback();
      }
    };
    let validSurePwd = (rule, value, callback) => {
      if (this.regForm.rePwd !== this.regForm.pwd) {
        callback(new Error(`两次输入的密码不一致`));
      } else {
        callback();
      }
    };
    let validSurePwd2 = (rule, value, callback) => {
      console.log(this.forgetForm, "this.forgetForm");
      if (this.forgetForm.rePwd2 !== this.forgetForm.pwd) {
        callback(new Error(`两次输入的密码不一致`));
      } else {
        callback();
      }
    };
    return {
      getMsgType: "",
      codeShow: false,
      codeURL: "",
      codeID: "",
      checkPhone: true,
      show: false,
      timer: null,
      second: 60,
      getMsgCode: false,
      login: "login", // register forget
      rememberPwd: false,
      activeInput: "",
      loading: false,
      regForm: {
        // 注册表单
        regVerCode: "",
        phone: "",
        project: "",
        pwd: "",
        rePwd: "",
      },
      forgetForm: {
        regVerCode: "",
        phone: "",
        pwd: "",
        rePwd2: "",
      },
      form: {
        // 登录表单
        username: "", // 账号 17884823877
        password: "", // 密码 Hwang793
        verCode: "",
      },
      codeForm: {
        code: "",
      },
      showPassword: false,
      routes: [],
      rules: {
        username: [{ validator: validPhone, trigger: "blur" }],
        // password: [{ validator: validValueNumAndLetter, trigger: "blur" }], // 登录不需要校验密码格式
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verCode: [{ validator: validVerCode, trigger: "blur" }],
        phone: [{ validator: validPhone, trigger: "blur" }],
        regVerCode: [{ validator: validVerCode, trigger: "blur" }],
        project: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        pwd: [{ validator: validValueNumAndLetter, trigger: "blur" }],
        rePwd: [{ validator: validSurePwd, trigger: "blur" }],
        rePwd2: [{ validator: validSurePwd2, trigger: "blur" }],
        code: [{ validator: validVerCode, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.getVerCode();
    this.rememberPwd = localStorage.getItem("remember")
      ? Boolean(localStorage.getItem("remember"))
      : false;
    if (this.rememberPwd) {
      // let info = JSON.parse(localStorage.getItem("loginAcc"))
      let info = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("loginAcc"),
          "hwang"
        ).toString(CryptoJS.enc.Utf8)
      );
      // console.log(info, "hwang")
      this.form.username = info.username;
      this.form.password = info.password;
    }
  },
  methods: {
    getVerCode() {
      this.codeID = "";
      this.codeURL = "";
      getCode().then((res) => {
        // console.log(res, "图形验证码")
        this.codeID = res.data.captchaId;
        this.codeURL = res.data.captchaImage;
      });
    },
    // 登录方法
    async doLogin() {
      // 动态路由添加
      //
      let param = {
        phoneNum: this.form.username,
        userPwd: this.form.password,
        captchaId: this.codeID,
        captchaVal: this.form.verCode,
      };
      // console.log(param, "pppp");
      this.$refs.form.validate((valid) => {
        if (valid) {
          // login(param).then(async (res) => {
          //   if (res.code === 200) {
          //     const route = await this.$router.selfAddRoutes(this.routes);
          //     localStorage.setItem("userInfo", JSON.stringify(res.data.user_info));
          //     this.$store.dispatch("login").then((res) => {
          //       // console.log(route);
          //       this.$store.commit("SET_LOGIN_STATUS", true);
          //       this.$router.push("/");
          //     });
          //   }
          //   this.form.verification = "";
          //   // this.getCaptchaURL();
          // });
          // this.$store.dispatch("login", param).then(async (res) => {
          login(param).then(async (res) => {
            // console.log(res, "有嘛");
            if(res.code == 403 && res.msg == '您的账号正在审核中，请耐心等待'){
              this.show = true
            } else if(res.code == 403 && res.msg == '您的账号被冻结，请联系管理员'){
              this.$message.error(res.msg)
            }
            // 非目录路由暂时先前端写死
            let menuNav = [
              {
                id: 11,
                pid: "0",
                name: "基本信息",
                icon: "",
                path: "baseInfo",
                hidden: true,
              },
              {
                id: 12,
                pid: "0",
                name: "开发应用",
                icon: "",
                path: "developApp",
                hidden: true,
              },
              {
                id: 13,
                pid: "0",
                name: "开发文档",
                icon: "",
                path: "document",
                hidden: true,
              },
              {
                id: 14,
                pid: "13",
                name: "开发文档",
                icon: "",
                path: "document",
                hidden: false,
              },
              {
                id: 15,
                pid: "13",
                name: "简介",
                icon: "",
                path: "introduction",
                hidden: false,
              },
              {
                id: 16,
                pid: "13",
                name: "生成access Token",
                icon: "",
                path: "token",
                hidden: false,
              },
              {
                id: 17,
                pid: "13",
                name: "状态码",
                icon: "",
                path: "code",
                hidden: false,
              },
            ];
            if(res.data.token){
              localStorage.setItem("token", JSON.stringify(res.data.token));
            }
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            localStorage.setItem("remember", this.rememberPwd);
            // localStorage.setItem("loginAcc", JSON.stringify(this.form));
            localStorage.setItem(
              "loginAcc",
              CryptoJS.AES.encrypt(JSON.stringify(this.form), "hwang")
            );
            this.$store.commit("permission/SET_ROLE_FLAG", res.data.role_ids); // 角色标识 1 管理员 0 普通用户
            let menus = res.data.permissions.concat(menuNav);
            let icons = ["icon-shebeiguanli", "icon-quanxianshenhe"];
            // 权限控制
            const route = await this.$router.selfAddRoutes(
              menus.map((f, index) => {
                f.icon = icons[index]; // 自己处理图标
                return {
                  ...f,
                  route: f.path,
                  pid: f.pid && f.pid == 0 ? "" : f.pid,
                };
              })
            );
            console.log(route, "---");
            // 此处处理一下菜单权限？
            if (res) {
              this.$store.commit('SET_LOGIN_STATUS', true)
              setCookie('open_token', res.data.token); // 刷新后vuex登陆状态会重置 存一份cookie用于判断
              this.$message.success("登录成功！");
              this.$router.push("/");
              // this.$router.push("/platformManage/orgManage");
            }
          });
        }
        this.getVerCode();
      });
    },
    verPhone() {
      this.$refs.forgetForm.validate((valid) => {
        if (valid) {
          console.log("通过");
          this.checkPhone = false;
        }
      });
    },
    // 提交后重置表单
    resetPwdForm(){
      if(this.timer){
        clearInterval(this.timer)
        this.timer = null
      }
      this.getMsgCode = false
      this.second = 60
      this.forgetForm = {
        regVerCode: "",
        phone: "",
        pwd: "",
        rePwd2: "",
      }
    },
    // 忘记密码
    toSetPwd() {
      let param = {
        phone_num: this.forgetForm.phone,
        user_pwd: this.forgetForm.pwd,
        verify_code: this.forgetForm.regVerCode,
      };
      // console.log(param, "重置")
      this.$refs.forgetForm.validate((valid) => {
        if (valid) {
          console.log("通过");
          resetPwd(param).then((res) => {
            if (res.code == 200) {
              this.$message.success("密码重置成功");
              this.checkPhone = true;
              this.login = "login";
              this.resetPwdForm()
            }
          });
        }
      });
    },
    // 获取短信验证码弹窗
    regGetCode(e) {
      this.$refs.regForm.validateField("phone", (valid) => {
        if (!valid) {
          // 校验通过
          this.getMsgType = e
          this.codeShow = true;
          this.getVerCode();
        }
      });
    },
    // 获取短信验证码弹窗
    forgetGetCode(e) {
      this.$refs.forgetForm.validateField("phone", (valid) => {
        if (!valid) {
          // 校验通过
          this.getMsgType = e
          this.codeShow = true;
          this.getVerCode();
        }
      });
    },
    // 获取短信验证码
    setTime() {
      this.$refs.codeForm.validate((valid) => {
        if (valid) {
          let param = {
            phoneNum: this.getMsgType == 'reg' ? this.regForm.phone : this.forgetForm.phone,
            captchaId: this.codeID,
            captchaVal: this.codeForm.code,
          };
          // console.log(param, "param")
          // return
          getMsgCode(param).then((res) => {
            if (res.code == 200) {
              this.codeShow = false;
              this.getMsgCode = true;
              this.timer = setInterval(() => {
                if (this.second <= 1) {
                  this.getMsgCode = false;
                  this.second = 60;
                  clearInterval(this.timer);
                  this.timer = null;
                } else {
                  this.second -= 1;
                }
              }, 1000);
              this.$message.success("获取短信验证码成功");
              this.$refs.codeForm.resetFields();
            }
            this.getVerCode();
          });
        }
      });
    },
    loginChange(type) {
      this.regForm.phone = this.form.username;
      this.forgetForm.phone = this.form.username;
      if (type == "login") {
        this.$refs.regForm.resetFields();
      } else if (type == "register" || type == "forget") {
        // this.$refs.form.resetFields();
      }
      this.login = type;
    },
    forgetBack(){
      this.login = "login";
      let arr = ['regVerCode','phone','pwd','rePwd2']
      this.$refs.forgetForm.clearValidate(arr);
      this.forgetForm = {
        regVerCode: "",
        phone: "",
        pwd: "",
        rePwd2: "",
      }
      this.checkPhone = true
    },
    register() {
      // 注册
      this.$refs.regForm.validate((valid) => {
        if (valid) {
          let param = {
            phoneNum: this.regForm.phone,
            projectName: this.regForm.project,
            userPwd: this.regForm.pwd,
            verifyCode: this.regForm.regVerCode,
          };
          // console.log(param, "pppp")
          register(param).then((res) => {
            if (res.code == 200) {
              this.$message.success("账户注册成功");
              this.$refs.regForm.resetFields();
              this.login = "login";
            }
          });
        }
      });
    },
    // 密码是否可见
    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleClick(type) {
      this.activeInput = type;
      //   console.log("222");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  // background: linear-gradient(225deg, #3361e2 0%, #00015f 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../../src/assets/images/login/login.png");
  .verCode {
    width: 120px;
    height: 38px;
    // background-color: #000;
    margin-left: 10px;
    cursor: pointer;
  }
  .main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    div {
      padding: 5px;
    }
    .content {
      font-weight: bold;
      font-size: 26px;
      color: #333333;
      line-height: 37px;
    }
  }
  .btns {
    display: flex;
    // align-items: center;
    justify-content: center;
  }
}

.lg {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_main {
    width: 432px;
    .blue_line {
      width: 432px;
      height: 4px;
      border-radius: 5px;
      background-color: #0077ff;
      margin: 16px 0;
    }
    .login_form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // height: 150px;
      margin: 20px 0;
      .el-input {
        margin: 10px 0;
      }
    }
    .reg_form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // height: 200px;
      margin: 20px 0;
      .el-input {
        margin: 10px 0;
      }
    }
    .check_pass {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
}
.login {
  position: absolute;
  top: 0px;
  right: 0px;
  height: calc(100vh);
  width: 580px;
  background-color: rgba(240, 244, 250, 0.9);
}
.ft1 {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.ft2 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.icon_style {
  margin-left: 5px;
  color: #0077ff;
}
::v-deep {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #999999;
  }
}
</style>
